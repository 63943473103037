@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@layer utilities {
  .filter-grayscale {
    filter: grayscale(100%);
  }
  .filter-none {
    filter: none;
  }
  /* ... */
}

html,
body {
  width: 100%;
  height: 100%;
}

.header-message h2 {
  line-height: 1.1 !important;
}

.truncate-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.Toastify__toast--default {
  background: transparent !important;
}
#tsparticles {
  position: absolute !important;
  right: 0;
  top: 0px;
  width: 100%;
  height: 100%;
}

.prickle-tooltip {
  @apply mb-6 font-medium text-white border-2 rounded-lg bg-dark;
}
